@import '~rsuite/dist/rsuite.min.css';

:root {
    --rs-gray-50: #f7f7fa;
    --rs-gray-100: #f2f2f5;
    --rs-gray-200: #e5e5ea;
    --rs-gray-300: #d9d9d9;
    --rs-gray-400: #c5c6c7;
    --rs-gray-500: #a6a6a6;
    --rs-gray-600: #8e8e93;
    --rs-gray-700: #7a7a7a;
    --rs-gray-800: #575757;
    --rs-gray-900: #272c36;
    --rs-primary-50: #f2faff;
    --rs-primary-100: #cce9ff;
    --rs-primary-200: #a6d7ff;
    --rs-primary-300: #80c4ff;
    --rs-primary-400: #59afff;
    --rs-primary-500: #3498ff;
    --rs-primary-600: #2589f5;
    --rs-primary-700: #1675e0;
    --rs-primary-800: #0a5dc2;
    --rs-primary-900: #004299;
    --rs-red-50: #fff2f2;
    --rs-red-100: #fccfcf;
    --rs-red-200: #faa9a7;
    --rs-red-300: #fa8682;
    --rs-red-400: #f7635c;
    --rs-red-500: #f44336;
    --rs-red-600: #eb3626;
    --rs-red-700: #d62915;
    --rs-red-800: #b81c07;
    --rs-red-900: #8f1300;
    --rs-orange-50: #fff8f2;
    --rs-orange-100: #ffdfc2;
    --rs-orange-200: #fcc690;
    --rs-orange-300: #fcb160;
    --rs-orange-400: #fa9b2f;
    --rs-orange-500: #fa8900;
    --rs-orange-600: #f08800;
    --rs-orange-700: #db8000;
    --rs-orange-800: #bd7100;
    --rs-orange-900: #945b00;
    --rs-yellow-50: #fffaf2;
    --rs-yellow-100: #ffe9c2;
    --rs-yellow-200: #ffd991;
    --rs-yellow-300: #ffca61;
    --rs-yellow-400: #ffbe30;
    --rs-yellow-500: #ffb300;
    --rs-yellow-600: #f5af00;
    --rs-yellow-700: #e0a500;
    --rs-yellow-800: #c29100;
    --rs-yellow-900: #997500;
    --rs-green-50: #eeffed;
    --rs-green-100: #c8f0c7;
    --rs-green-200: #a5e0a4;
    --rs-green-300: #82cf82;
    --rs-green-400: #65bf67;
    --rs-green-500: #4caf50;
    --rs-green-600: #37ab3c;
    --rs-green-700: #22a12a;
    --rs-green-800: #0f9119;
    --rs-green-900: #007d0c;
    --rs-cyan-50: #f2ffff;
    --rs-cyan-100: #bcf4f7;
    --rs-cyan-200: #87e6ed;
    --rs-cyan-300: #57dae6;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-cyan-700: #00a0bd;
    --rs-cyan-800: #008aa6;
    --rs-cyan-900: #006e87;
    --rs-blue-50: #f0f9ff;
    --rs-blue-100: #c5e7fc;
    --rs-blue-200: #9bd4fa;
    --rs-blue-300: #72c0f7;
    --rs-blue-400: #49abf5;
    --rs-blue-500: #2196f3;
    --rs-blue-600: #1787e8;
    --rs-blue-700: #0d73d4;
    --rs-blue-800: #045cb5;
    --rs-blue-900: #00448c;
    --rs-violet-50: #f6f2ff;
    --rs-violet-100: #d5c9f0;
    --rs-violet-200: #b6a1e3;
    --rs-violet-300: #987bd4;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-violet-700: #531ba8;
    --rs-violet-800: #470c99;
    --rs-violet-900: #390085;
    --rs-state-success: #4caf50;
    --rs-state-info: #2196f3;
    --rs-state-warning: #ffb300;
    --rs-state-error: #f44336;
    --rs-body: #fff;
    --rs-bg-success: #edfae1;
    --rs-bg-info: #e9f5fe;
    --rs-bg-warning: #fff9e6;
    --rs-bg-error: #fde9ef;
    --rs-text-link: #1675e0;
    --rs-text-link-hover: #0a5dc2;
    --rs-text-link-active: #004299;
    --rs-text-primary: #575757;
    --rs-text-secondary: #8e8e93;
    --rs-text-tertiary: #a6a6a6;
    --rs-text-heading: #272c36;
    --rs-text-inverse: #f7f7fa;
    --rs-text-heading-inverse: #fff;
    --rs-text-active: #1675e0;
    --rs-text-disabled: #c5c6c7;
    --rs-text-error: #f44336;
    --rs-border-primary: #e5e5ea;
    --rs-border-secondary: #f2f2f5;
    --rs-bg-card: #fff;
    --rs-bg-overlay: #fff;
    --rs-bg-well: #f7f7fa;
    --rs-bg-active: #3498ff;
    --rs-bg-backdrop: rgba(39, 44, 54, .3);
    --rs-state-hover-bg: #f2faff;
    --rs-color-focus-ring: rgba(52, 152, 255, .25);
    --rs-state-focus-shadow: 0 0 0 3px rgba(52, 152, 255, .25);
    --rs-state-focus-outline: 3px solid rgba(52, 152, 255, .25);
    --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
    --rs-btn-default-bg: #f7f7fa;
    --rs-btn-default-text: #575757;
    --rs-btn-default-hover-bg: #e5e5ea;
    --rs-btn-default-active-bg: #d9d9d9;
    --rs-btn-default-active-text: #272c36;
    --rs-btn-default-disabled-bg: #f7f7fa;
    --rs-btn-default-disabled-text: #c5c6c7;
    --rs-btn-primary-bg: #3498ff;
    --rs-btn-primary-text: #fff;
    --rs-btn-primary-hover-bg: #2589f5;
    --rs-btn-primary-active-bg: #1675e0;
    --rs-btn-subtle-text: #8e8e93;
    --rs-btn-subtle-hover-bg: #e5e5ea;
    --rs-btn-subtle-hover-text: #575757;
    --rs-btn-subtle-active-bg: #e5e5ea;
    --rs-btn-subtle-active-text: #272c36;
    --rs-btn-subtle-disabled-text: #c5c6c7;
    --rs-btn-ghost-border: #1675e0;
    --rs-btn-ghost-text: #1675e0;
    --rs-btn-ghost-hover-border: #0a5dc2;
    --rs-btn-ghost-hover-text: #0a5dc2;
    --rs-btn-ghost-active-border: #004299;
    --rs-btn-ghost-active-text: #004299;
    --rs-btn-link-text: #1675e0;
    --rs-btn-link-hover-text: #0a5dc2;
    --rs-btn-link-active-text: #004299;
    --rs-iconbtn-addon: #f2f2f5;
    --rs-iconbtn-activated-addon: #d9d9d9;
    --rs-iconbtn-pressed-addon: #c5c6c7;
    --rs-iconbtn-primary-addon: #2589f5;
    --rs-iconbtn-primary-activated-addon: #1675e0;
    --rs-iconbtn-primary-pressed-addon: #0a5dc2;
    --rs-divider-border: #e5e5ea;
    --rs-loader-ring: rgba(247, 247, 250, .8);
    --rs-loader-rotor: #a6a6a6;
    --rs-loader-backdrop: hsla(0, 0%, 100%, .9);
    --rs-loader-ring-inverse: rgba(247, 247, 250, .3);
    --rs-loader-rotor-inverse: #fff;
    --rs-loader-backdrop-inverse: rgba(39, 44, 54, .83);
    --rs-message-success-header: var(--rs-text-heading);
    --rs-message-success-text: var(--rs-text-primary);
    --rs-message-success-icon: #4caf50;
    --rs-message-success-bg: #eeffed;
    --rs-message-info-header: var(--rs-text-heading);
    --rs-message-info-text: var(--rs-text-primary);
    --rs-message-info-icon: #2196f3;
    --rs-message-info-bg: #f0f9ff;
    --rs-message-warning-header: var(--rs-text-heading);
    --rs-message-warning-text: var(--rs-text-primary);
    --rs-message-warning-icon: #ffb300;
    --rs-message-warning-bg: #fffaf2;
    --rs-message-error-header: var(--rs-text-heading);
    --rs-message-error-text: var(--rs-text-primary);
    --rs-message-error-icon: #f44336;
    --rs-message-error-bg: #fff2f2;
    --rs-tooltip-bg: #272c36;
    --rs-tooltip-text: #fff;
    --rs-progress-bg: #e5e5ea;
    --rs-progress-bar: #3498ff;
    --rs-progress-bar-success: #4caf50;
    --rs-progress-bar-fail: #f44336;
    --rs-placeholder: #f2f2f5;
    --rs-placeholder-active: #e5e5ea;
    --rs-breadcrumb-item-active-text: #272c36;
    --rs-dropdown-divider: #e5e5ea;
    --rs-dropdown-item-bg-hover: rgba(204, 233, 255, .5);
    --rs-dropdown-item-bg-active: #f2faff;
    --rs-dropdown-item-text-active: #1675e0;
    --rs-dropdown-header-text: #a6a6a6;
    --rs-dropdown-shadow: 0 0 10px rgba(0, 0, 0, .06), 0 4px 4px rgba(0, 0, 0, .12);
    --rs-menuitem-active-bg: rgba(204, 233, 255, .5);
    --rs-menuitem-active-text: #1675e0;
    --rs-steps-border: #8e8e93;
    --rs-steps-state-finish: #3498ff;
    --rs-steps-border-state-finish: #3498ff;
    --rs-steps-state-wait: #8e8e93;
    --rs-steps-state-process: #3498ff;
    --rs-steps-state-error: #f44336;
    --rs-steps-border-state-error: #f44336;
    --rs-steps-icon-state-process: #3498ff;
    --rs-steps-icon-state-error: #f44336;
    --rs-navs-text: #8e8e93;
    --rs-navs-text-hover: #575757;
    --rs-navs-bg-hover: #e5e5ea;
    --rs-navs-text-active: #272c36;
    --rs-navs-bg-active: #e5e5ea;
    --rs-navs-tab-border: #d9d9d9;
    --rs-navs-subtle-border: #f7f7fa;
    --rs-navs-selected: #1675e0;
    --rs-navbar-default-bg: #f7f7fa;
    --rs-navbar-default-text: #575757;
    --rs-navbar-default-selected-text: #1675e0;
    --rs-navbar-default-hover-bg: #e5e5ea;
    --rs-navbar-default-hover-text: #575757;
    --rs-navbar-inverse-bg: #3498ff;
    --rs-navbar-inverse-text: #fff;
    --rs-navbar-inverse-selected-bg: #1675e0;
    --rs-navbar-inverse-hover-bg: #2589f5;
    --rs-navbar-inverse-hover-text: #fff;
    --rs-navbar-subtle-bg: #fff;
    --rs-navbar-subtle-text: #8e8e93;
    --rs-navbar-subtle-selected-text: #1675e0;
    --rs-navbar-subtle-hover-bg: #f7f7fa;
    --rs-navbar-subtle-hover-text: #575757;
    --rs-sidenav-default-text: #575757;
    --rs-sidenav-default-selected-text: #1675e0;
    --rs-sidenav-default-hover-bg: #e5e5ea;
    --rs-sidenav-default-hover-text: #575757;
    --rs-sidenav-default-footer-border: #e5e5ea;
    --rs-sidenav-inverse-bg: #3498ff;
    --rs-sidenav-inverse-text: #fff;
    --rs-sidenav-inverse-selected-bg: #1675e0;
    --rs-sidenav-inverse-hover-bg: #2589f5;
    --rs-sidenav-inverse-footer-border: #2589f5;
    --rs-sidenav-subtle-bg: #fff;
    --rs-sidenav-subtle-text: #8e8e93;
    --rs-sidenav-subtle-selected-text: #1675e0;
    --rs-sidenav-subtle-hover-bg: #f7f7fa;
    --rs-sidenav-subtle-hover-text: #575757;
    --rs-sidenav-subtle-footer-border: #e5e5ea;
    --rs-input-bg: #fff;
    --rs-input-focus-border: #3498ff;
    --rs-input-disabled-bg: #f7f7fa;
    --rs-listbox-option-hover-bg: rgba(204, 233, 255, .5);
    --rs-listbox-option-hover-text: #1675e0;
    --rs-listbox-option-selected-text: #1675e0;
    --rs-listbox-option-selected-bg: #f2faff;
    --rs-listbox-option-disabled-text: #c5c6c7;
    --rs-listbox-option-disabled-selected-text: #a6d7ff;
    --rs-checkbox-icon: #fff;
    --rs-checkbox-border: #d9d9d9;
    --rs-checkbox-checked-bg: #3498ff;
    --rs-checkbox-disabled-bg: #f7f7fa;
    --rs-radio-marker: #fff;
    --rs-radio-border: #d9d9d9;
    --rs-radio-checked-bg: #3498ff;
    --rs-radio-disabled-bg: #f7f7fa;
    --rs-rate-symbol: #8e8e93;
    --rs-rate-symbol-checked: #ffb300;
    --rs-toggle-bg: #d9d9d9;
    --rs-toggle-thumb: #fff;
    --rs-toggle-loader-ring: rgba(247, 247, 250, .3);
    --rs-toggle-loader-rotor: #fff;
    --rs-toggle-hover-bg: #c5c6c7;
    --rs-toggle-disabled-bg: #f7f7fa;
    --rs-toggle-disabled-thumb: #fff;
    --rs-toggle-checked-bg: #3498ff;
    --rs-toggle-checked-thumb: #fff;
    --rs-toggle-checked-hover-bg: #2589f5;
    --rs-toggle-checked-disabled-bg: #cce9ff;
    --rs-toggle-checked-disabled-thumb: #fff;
    --rs-slider-bar: #f2f2f5;
    --rs-slider-hover-bar: #e5e5ea;
    --rs-slider-thumb-border: #3498ff;
    --rs-slider-thumb-bg: #fff;
    --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, .25);
    --rs-slider-progress: #3498ff;
    --rs-uploader-item-bg: #d9d9d9;
    --rs-uploader-item-hover-bg: #f7f7fa;
    --rs-uploader-overlay-bg: hsla(0, 0%, 100%, .8);
    --rs-uploader-dnd-bg: #fff;
    --rs-uploader-dnd-border: #e5e5ea;
    --rs-uploader-dnd-hover-border: #3498ff;
    --rs-avatar-bg: #d9d9d9;
    --rs-avatar-text: #fff;
    --rs-badge-bg: #f44336;
    --rs-badge-text: #fff;
    --rs-tag-bg: #f7f7fa;
    --rs-tag-close: #f44336;
    --rs-carousel-bg: #8e8e93;
    --rs-carousel-indicator: hsla(0, 0%, 100%, .4);
    --rs-carousel-indicator-hover: #fff;
    --rs-carousel-indicator-active: #3498ff;
    --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
    --rs-list-bg: #fff;
    --rs-list-border: #e5e5ea;
    --rs-list-hover-bg: #f2faff;
    --rs-list-placeholder-bg: rgba(242, 250, 255, .5);
    --rs-list-placeholder-border: #3498ff;
    --rs-timeline-indicator-bg: #d9d9d9;
    --rs-timeline-indicator-active-bg: #3498ff;
    --rs-table-shadow: rgba(9, 9, 9, .08);
    --rs-table-sort: #3498ff;
    --rs-table-resize: #3498ff;
    --rs-table-scrollbar-track: #e5e5ea;
    --rs-table-scrollbar-thumb: #575757;
    --rs-table-scrollbar-thumb-active: #272c36;
    --rs-table-scrollbar-vertical-track: rgba(229, 229, 234, .4);
    --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
    --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, .12), 0 0 10px rgba(0, 0, 0, .06);
    --rs-form-errormessage-text: #f44336;
    --rs-form-errormessage-bg: #fff;
    --rs-form-errormessage-border: #e5e5ea;
    --rs-picker-value: #1675e0;
    --rs-picker-count-bg: #3498ff;
    --rs-picker-count-text: #fff;
    --rs-calendar-today-bg: #3498ff;
    --rs-calendar-today-text: #fff;
    --rs-calendar-range-bg: rgba(204, 233, 255, .5);
    --rs-calendar-time-unit-bg: #f7f7fa;
    --rs-calendar-date-selected-text: #fff;
    --rs-calendar-cell-selected-hover-bg: #1675e0;
    --rs-popover-shadow: 0 1px 8px rgba(0, 0, 0, .12);
}

html {
    padding: 0;
}

body {
    margin: 0;
    height: 100%;
    background-color: #f6f6f7;
}

form {
    display: flex;
    width: 100%;
}

footer {
    color: #575757;
    margin-top: 3rem;
    padding: 1rem;
    font-weight: 900;
    text-align: center;

    nav {
        margin: 1rem;

        li {
            margin: 1rem 0;
            list-style-type: none;

            a {
                color: #FFFFFF;
                font-weight: 700;
                text-decoration: none;
                padding: 0.5rem 0;

                &:visited {
                    color: #FFFFFF;
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                    color: #FFFFFF;
                    transition: ease .2s;
                    box-shadow: inset 0 -6px 0 0 #0066a1;
                }
            }
        }
    }
}

.side-nav {

    //desktop
    @media (min-width: 1025px) and (max-width: 1280px) {
        display: flex;
    }

    //mobile
    @media (min-width: 320px) and (max-width: 480px) {
        display: none;
    }
}


.account-page {
    h1 a {
        display: block;
        text-align: center;
        margin: 1.5rem
    }

    .card {
        display: block;
        margin: 1rem;
    }
}

.text-align-center {
    text-align: center;
}

button {
    background-color: #0066a1;
    border: none;
    border-radius: 1rem;
    color: #ffffff;
    padding: .7rem 1.5rem;

    &:focus-visible {
        outline: 2px solid #101820;
    }

    &:disabled {
        background-color: gray;
        color: #ffffff;
        border-color: gray;
    }

    &:hover {
        transition: ease .2s;
        background-color: #005281;
    }
}

.secondary-button {
    background-color: unset;
    border: solid 2px #575757;
    color: #575757;
    font-weight: 700;

    &:hover {
        transition: ease .2s;
        background-color: #e0e0e0;
    }
}


.logo-bar {
    display: flex;
    justify-content: space-between;
    margin: 1rem;

    .logo-login {
        display: block;
        margin: auto;
    }

}

a {
    color: #101820;
    text-decoration: none;

    &:visited {
        color: #101820;
    }
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.justify-space-between {
    justify-content: space-between;
}

.flex-half {
    flex: 1;
    padding: 1rem;
}

.justify-center {
    justify-content: center;
}

.app-bar {
    background-color: #101820;
    min-height: 4rem;
    display: flex;
    flex-direction: row;

    .account {
        a {
            display: flex;
            padding-left: 1rem;
            margin: 1rem;

            svg {
                border-radius: 0.5rem;
            }
        }
    }

    .right-menu {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0 2rem;
        align-items: center;
        justify-content: flex-end;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;

            label {
                display: flex;
                flex-direction: column;
                padding: 1rem;
                color: #ffffff;
            }
        }
    }
}

.error-bar {
    display: flex;
    flex-direction: row;
    background-color: #ff5353;
    color: #FFFFFF;
    margin: 1rem 0;
    padding: 0 0.5rem;
    border-radius: 1rem;
    width: 100%;

    .error-message {
        flex: 80%;
        display: flex;
        align-items: center;
    }

    .error-button {
        flex: 20%;
        display: flex;
        justify-content: flex-end;
    }
}

.mobile-nav {
    display: none;

    //mobile
    @media (min-width: 320px) and (max-width: 480px) {
        display: flex;
    }

    .nav-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }

}

.main-container {
    display: flex;
    flex-direction: row;
    background-color: #f6f6f7;

    .main-section {
        display: flex;
        flex-direction: column;
        margin: 1rem;
        padding: 1rem;
        width: 100%;
        overflow: scroll;

        //mobile
        @media (min-width: 320px) and (max-width: 480px) {
            margin: 0;
            padding: .5rem;
        }
    }

    nav {
        height: 100vh;
        background-color: #ffffff;
        border-radius: 0rem 1rem 1rem 0;
        box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
    }
}

.content-container {
    display: block;
    margin: auto;
    max-width: 1280px;
}

.card {
    display: flex;
    box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));
    border-radius: 1rem;
    padding: .7rem;
    background-color: #ffffff;

    .title {
        font-size: 2rem;
        font-weight: 600;
    }
}



.main-nav-flex {
    display: flex;
    flex-direction: row;
    background-color: #575757;

    li {
        display: flex;
        flex: auto;
        justify-content: space-around;
        height: 3rem;

        .active {
            background-color: #0066a1;
            margin: 0;
        }

        a {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            font-weight: 700;
            text-decoration: none;
            padding: 1rem;

            &:hover {
                box-shadow: inset 0 -4px 0 0 #FFFFFF;
            }
        }
    }
}

.contact-us {
    .box-padding {
        margin-right: 4rem;
    }

    .flex-half {
        display: block;
        width: 50%;
    }
}